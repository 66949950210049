// theme.scss
@use '@angular/material' as mat;
@import '@angular/material/theming';

$primary-cust: (
    50 : #e8e8ea,
    100 : #c5c6ca,
    200 : #9ea0a7,
    300 : #777a84,
    400 : #595e69,
    500 : #3c414f,
    600 : #363b48,
    700 : #2e323f,
    800 : #272a36,
    900 : #1a1c26,
    A100 : #6d8aff,
    A200 : #3a61ff,
    A400 : #0738ff,
    A700 : #002fec,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$secondary-cust: (
    50 : #e4eef6,
    100 : #bbd4e9,
    200 : #8eb8db,
    300 : #619bcd,
    400 : #3f85c2,
    500 : #1d70b7,
    600 : #1a68b0,
    700 : #155da7,
    800 : #11539f,
    900 : #0a4190,
    A100 : #bed6ff,
    A200 : #8bb5ff,
    A400 : #5894ff,
    A700 : #3f84ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$corporate-medium: #48657B;
$corporate-dark: #244545;
$medium-gray: #323941;
$light-gray: #FAFAFA;
$white: #FFFFFF;
$success: #20B901;
$warning: orange;
$error: #CC0505;
$corporate-blue: #1d70b7;

$quotit-theme-primary: mat.define-palette($primary-cust);
$quotit-theme-accent : mat.define-palette($secondary-cust);
$quotit-theme-warn   : mat.define-palette(mat.$red-palette);

$quotit-theme: mat.define-light-theme(
    $quotit-theme-primary,
    $quotit-theme-accent,
    $quotit-theme-warn,
);

$background: map-get($quotit-theme, background);
$background: map_merge($background, (background: $light-gray));
$quotit-theme: map_merge($quotit-theme, (background: $background));

@function my-mat-light-theme-foreground($color) {
  @return (
    base:              $color,
    divider:           $dark-dividers,
    dividers:          $dark-dividers,
    disabled:          rgba($color, 0.38),
    disabled-button:   rgba($color, 0.26),
    disabled-text:     rgba($color, 0.38),
    elevation:         black,
    hint-text:         rgba($color, 0.38),
    secondary-text:    $color,
    icon:              rgba($color, 0.54),
    icons:             rgba($color, 0.54),
    text:              $color,
    slider-min:        rgba($color, 0.87),
    slider-off:        rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
  );
};
$foreground: my-mat-light-theme-foreground($corporate-medium);
$quotit-theme: map-merge($quotit-theme, (foreground: $foreground));
