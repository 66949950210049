
/* ===============
   COMMON STYLES
   =============== */

/* Button Sidebar Toggle / Botón Interruptor Barra Lateral */
.btn-sidebar-toggle {
    border: 1px solid #fff;
    border-color: #fff !important;
    border-radius: 2px;
    padding: 8px 12px;
    display: inline-block;
    opacity: 1;
}
.btn-sidebar-toggle .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    display: inline-block;
}
.btn-sidebar-toggle .icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
}

/* Bottom Bar / Barra Inferior */
.bottom-bar {
    height: 4px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #3BCCDD;
    z-index: 2;
}


/* ===============
   HOME PAGE
   =============== */

/* Lead Text / Texto Destacado */
.lead-text {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    max-width: 440px;
    margin-bottom: 64px;
}
@media (max-width: 767.98px) {
    .lead-text {
        font-size: 16px;
        margin-bottom: 32px;
    }
}

.panel-div-title {
    display: flex;
    box-sizing: border-box;
    padding:10px 0px 5px 16px;
    width: 100%;
    flex-direction: column;
    white-space: nowrap;
    background: white;
    color: #48657b;
    font: 700 20px / 30px "IBM Plex Sans", sans-serif;
    letter-spacing: normal;
    border-bottom: 1px solid #cccc;
}

.tab-left {
    padding-left: 30px !important;
}

.subt-left {
    padding: 15px;
    border: 0;
    padding-left: 30px;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    white-space: nowrap;
    background: #FAFAFA;
    color: #48657b;
}

.grid-label-dash {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
    box-sizing: border-box;
}

.grid-label-dash-box {
    background: linear-gradient(256deg, #88c0d0 0%, #1d70b7 100%);
}

.widget-overview-box {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    min-height: 90px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-nogutter {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    margin: 20px;
}

.label-dash-box {
    color: #48657b;
}

.shadow-1 {
    box-shadow: 0 3px 5px #00000005,0 0 2px #0000000d,0 1px 4px #00000014!important;
}

.card-label {
    background: var(--surface-card);
    padding: 1.25rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
    border-radius: var(--border-radius);
    margin-right: 10px;
}

.card-blue {
    border-color: rgb(100, 181, 246);
    border-left-width: 4px;
    border-left-style: solid;
}

.card-purple {
    border-color: rgb(121, 134, 203);
    border-left-width: 4px;
    border-left-style: solid;
}

.card-green {
    border-color: rgb(77, 182, 172);
    border-left-width: 4px;
    border-left-style: solid;
}

.card-blue-white {
    border-color: rgb(77, 208, 225);
    border-left-width: 4px;
    border-left-style: solid;
}

.card-grid {
    background: #FDFEFF;
    padding: 12px 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 2rem;
    margin-left: 10px;
}

.card-grid .card-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 6px;
}

.overview-box .card-header {
    padding-bottom: 17px;
}

.overview-box .overview-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.overview-box .overview-content .overview-graph {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
}

.overview-box .overview-content .overview-graph > span > span {
    font-size: 12px;
    min-width: 6px;
    position: absolute;
    padding: 1px;
    border-radius: 2px;
    background-color: #E8F1F8;
    top: -22px;
    left: -4px;
    display: none;
    z-index: 2;
}

.overview-box.blue .overview-content .overview-graph > span {
    width: 8px;
    height: 12px;
    display: block;
    background-color: #2f8ee5;
    margin-left: 4px;
    border-radius: 1px;
    opacity: 0.4;
    cursor: pointer;
    position: relative;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 1;
}

.overview-box-label {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
    font-size: 1.75rem;
}

.card-header-title {
    margin-bottom: 0;
    color: #657380;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    font-size: 0.9rem;
}

.box-subcard {
    box-sizing: border-box;
    padding: 1rem !important;
    flex-direction: column !important;
    text-align: center !important;
}

.box-subcard-title {
    font-weight: 600;
}

.box-subcard-border {
    border-right-width: 1px !important;
    border-right-style: solid;
}

.surface-border {
    border-color: #dee2e6 !important;
}

.overview-box.orange .overview-content .overview-graph > span {
    background-color: #f1b263;
}


.overview-box .overview-content .overview-graph > span {
    width: 8px;
    height: 12px;
    display: block;
    background-color: #2f8ee5;
    margin-left: 4px;
    border-radius: 1px;
    opacity: 0.4;
    cursor: pointer;
    position: relative;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 1;
}

.overview-box.teal .overview-content .overview-graph > span {
    background-color: #00acac;
}

.overview-box-icon {
    margin: auto;
    vertical-align: middle;
    color: rgb(100 181 246 / 43%);
    font-size: 20px !important; 
    height: 24px !important;
    width: 24px !important;
}
